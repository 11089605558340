<template>
  <div v-if="!requestConnectCustomer" class="inner-content px-5 py-5 text-center">
    <div class="fs-4 fw-normal mt-2">{{ $t('connect_to_company') }}</div>
    <div class="py-2 fs-3 my-3 alert-msg">{{ $t('input_provided_code_to_connect') }}</div>
    <ion-input
      type="tel"
      maxlength="6"
      v-model="pin"
      :placeholder="t('enter_six_digits_pin_placeholder')"
      class="pin-input"
      @keypress="checkInput"
      inputmode="numeric"
    ></ion-input>
    <ion-label v-if="retryPinDuration > 0">
      <ion-text>{{ $t('please_wait_for') }} {{ retryPinDuration }}s </ion-text>
    </ion-label>
    <div class="button-container">
      <ion-button @click="goBack" class="back-button">
        <ion-text> {{ $t('back') }} </ion-text>
      </ion-button>

      <ion-button
        @click="getConnectCustomerRequest"
        :disabled="!pin || pin.length < 6 || retryPinDuration > 0"
        class="confirm-button"
      >
        <ion-text> {{ $t('confirm') }} </ion-text>
      </ion-button>
    </div>
  </div>

  <div v-if="requestConnectCustomer" class="inner-content px-5 py-5">
    <div class="fs-4 fw-normal mt-2 text-center">
      {{ $t('connecting_to') }}
    </div>
    <div class="request-info">
      <span>
        <ion-text class="fw-bold">{{ $t('company') }}: </ion-text>
        <ion-text
          >[{{ requestConnectCustomer.customer.account_number }}]-{{
            requestConnectCustomer.customer_name
          }}</ion-text
        >
      </span>
    </div>
    <div class="request-info">
      <span>
        <ion-text class="fw-bold">{{ $t('contact') }}: </ion-text>
        <ion-text
          >{{ user.first_name }} {{ user.last_name || '' }}, +{{ user.mobile }},
          {{ user.email || '' }}</ion-text
        >
      </span>
    </div>
    <div class="fs-4 fw-normal mt-6 text-center">
      {{ $t('do_you_accept_connection') }}
    </div>

    <div class="button-container">
      <ion-button @click="goBack" class="back-button">
        <ion-text> {{ $t('back') }} </ion-text>
      </ion-button>

      <ion-button @click="confirmConnectCustomer" class="confirm-button">
        <ion-text> {{ $t('confirm') }} </ion-text>
      </ion-button>
    </div>
  </div>

  <ion-loading
    mode="ios"
    cssClass="my-custom-class"
    :message="`${$t('please_wait')}. . .`"
    :is-open="isLoading"
  >
  </ion-loading>
</template>

<script>
import { apolloClient } from '@/main';
import { IonInput, alertController } from '@ionic/vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { addConnectPerson, checkConnectPersonByCode } from '../../../services/graphql/customer';

export default {
  components: {
    IonInput
  },
  inject: ['$storage'],
  setup() {
    const { t } = useI18n();
    const pin = ref(null);
    const user = ref(null);
    const retryLimit = ref(5);
    return {
      pin,
      user,
      t,
      retryLimit
    };
  },
  data() {
    return {
      requestConnectCustomer: null,
      isLoading: false,
      inputPinCounter: 0,
      retryPinDuration: 0
    };
  },
  async mounted() {
    this.user = await this.$storage.getUser();
  },
  methods: {
    goBack() {
      this.requestConnectCustomer = null;
      this.pin = null;
      this.$emit('close-modal');
    },
    async getConnectCustomerRequest() {
      this.isLoading = true;
      try {
        const res = await apolloClient.query({
          query: checkConnectPersonByCode,
          variables: { code: this.pin }
        });

        if (res.errors && res.errors.length > 0) {
          this.presentErrorAlert(res.errors[0].message);
          this.increaseInputPinCounter();
        }

        this.requestConnectCustomer = res.data.checkConnectPersonByCode;
      } catch (error) {
        this.presentErrorAlert(error.message);
      } finally {
        this.isLoading = false;
      }
    },
    async confirmConnectCustomer() {
      this.isLoading = true;
      try {
        const res = await apolloClient.mutate({
          mutation: addConnectPerson,
          variables: {
            requestId: this.requestConnectCustomer.id,
            code: this.pin,
            isAccept: true
          }
        });

        if (res.errors && res.errors.length > 0) {
          this.presentErrorAlert(res.errors[0].message);
          return;
        }

        window.location.reload();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    checkInput(event) {
      if (this.pin?.length === 6 || isNaN(event.key)) {
        event.preventDefault();
      }
    },
    presentErrorAlert(message) {
      alertController
        .create({
          header: 'Ooops!',
          message: message,
          buttons: [
            {
              text: 'OK',
              handler: () => {
                this.pin = null;
              }
            }
          ],
          mode: 'ios',
          backdropDismiss: false
        })
        .then((alert) => alert.present());
    },
    increaseInputPinCounter() {
      this.inputPinCounter++;

      if (this.inputPinCounter === this.retryLimit) {
        this.presentErrorAlert(this.$t('pic_pin_input_limit_message', { retry_limit: this.retryLimit }));
        this.retryPinDuration = 60;
        const interval = setInterval(() => {
          this.retryPinDuration--;
          if (this.retryPinDuration <= 0) {
            this.inputPinCounter = 0;
            clearInterval(interval);
          }
        }, 1000);
      }
    }
  }
};
</script>

<style scoped>
.pin-input {
  font-size: 1.5rem;
  text-align: center;
  margin: 0 auto;
}

.modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
}

.content {
  flex-grow: 1;
  text-align: center;
}

.button-container {
  display: flex;
  width: 100%;
  gap: 1rem;
  margin-top: 10px;
}

.back-button,
.confirm-button {
  flex: 1;
}

.request-info {
  font-size: 1.25rem;
  line-height: 1.4; /* Controls line spacing */
  margin-top: 20px;
  margin-bottom: 2px;
  word-break: break-word; /* Forces long words to break to avoid overflow */
}
</style>
