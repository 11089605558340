<template>
  <ion-grid class="w-100 py-4 px-2 mx-0 shadow-bottom">
    <ion-radio-group
      :value="currentPage === '/sale/select-customer' ? customerSelect?.id || null : null"
      mode="md"
    >
      <ion-row class="ion-align-items-start" @click="onTapCustomerCard(item.id)">
        <ion-col size="auto">
          <div class="image-default" :style="`background: ${randomBg?.background}; color:${randomBg?.color}`">
            {{ getInitialName(item.name || item.customer_name) }}
          </div>
        </ion-col>
        <ion-col>
          <ion-row>
            <ion-text class="fw-700 fs-2">
              {{ item.name || item.customer_name }}
            </ion-text>
          </ion-row>
          <ion-row class="ion-align-items-center">
            <ion-text class="text-gray-700 fw-500 fs-2 py-1">{{ item?.account_number ?? '-' }}</ion-text>
            <ion-chip class="b2c-label-chip" v-if="item.is_b2c">B2C</ion-chip>
            <ion-col v-if="item?.black_list" size="auto" style="margin-right: -5px">
              <div class="box-label d-flex align-center">
                <ion-icon class="fs-2" :icon="banOutline" color="danger"></ion-icon>
                <span class="text-danger ml-1 fs-1 fw-500">{{ $t('black_listed') }}</span>
              </div>
            </ion-col>
            <ion-col v-if="item?.has_credit_block" size="auto">
              <div class="box-label d-flex align-center credit-block">
                <span class="text-white fs-1">{{ $t('credit_block') }}</span>
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-text class="fs-2">{{ item.address || $t('not_provided_address') }}</ion-text>
          </ion-row>
        </ion-col>
        <ion-col v-if="currentPage === '/sale/select-customer'" size="auto">
          <ion-radio :value="item.id"></ion-radio>
        </ion-col>
      </ion-row>
      <div class="form-btn" v-if="isPendingReview">
        <ion-button mode="md" fill="outline" size="small" @click="onTapContactUs">
          <span style="text-transform: capitalize">{{ $t('contact_us') }}</span>
          <img :src="whatsappIcon" style="margin-left: 5px" />
        </ion-button>
      </div>
      <div class="form-btn" v-if="isPendingRequest">
        <ion-button mode="md" class="btn" fill="outline" size="small">
          <span style="text-transform: capitalize">{{ $t('reject') }}</span>
        </ion-button>
        <ion-button mode="md" class="btn" size="small">
          <span style="text-transform: capitalize">{{ $t('accept') }}</span>
        </ion-button>
      </div>
    </ion-radio-group>
  </ion-grid>
</template>
<script>
import { useCardCompany } from '@/usecases/customer';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'CardCompany',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    currentPage: {
      type: String,
      default: ''
    },
    customerSelect: {
      type: Object,
      default: () => ({})
    },
    activeTab: {
      type: String,
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    },
    isPendingRequest: {
      type: Boolean,
      default: false
    },
    isPendingReview: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onSelectCustomer'],
  setup(props, context) {
    const {
      isDefaultImage,
      randomBg,
      whatsappIcon,
      addressCompanyinReq,
      getAddressFormatWithStall,
      handleRandomBackground,
      onTapContactUs,
      getInitialName,
      onTapCustomerCard,
      banOutline
    } = useCardCompany(props, context);
    return {
      isDefaultImage,
      randomBg,
      whatsappIcon,
      addressCompanyinReq,
      getAddressFormatWithStall,
      handleRandomBackground,
      onTapContactUs,
      getInitialName,
      onTapCustomerCard,
      banOutline
    };
  }
});
</script>
<style lang="scss" scoped>
.label-card {
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 3px;
}
.image-default {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14.4px;
  line-height: 17px;
}

.form-btn {
  display: flex;
  justify-content: flex-end;
}
.box-label {
  background-color: var(--ion-color-text-error-100);
  width: fit-content;
  padding: 3px 7px;
  border-radius: 4px;
  &.credit-block {
    background-color: #f15c51;
  }
}

.shadow-bottom {
  box-shadow: 0px 1px 1px 0px rgb(171, 171, 171);
}
</style>
