<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar no-border>
        <ion-grid>
          <ion-row v-if="previousSelectedCompanyId" class="align-center" id="row">
            <ion-col size="9" class="d-flex">
              <ion-buttons class="mr-2">
                <ion-back-button
                  text=""
                  color="primary"
                  mode="ios"
                  @click="goBack"
                  class="back-icon"
                  :default-href="prevPage"
                ></ion-back-button>
              </ion-buttons>
              <ion-label class="fs-3 fw-600 mt-label">{{
                currentPage === '/sale/select-customer'
                  ? $t('select_customer')
                  : currentPage === '/sale/customers'
                  ? $t('manage_customers')
                  : ''
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row v-if="!previousSelectedCompanyId" class="ion-align-items-center">
            <ion-col class="title ion-text-start" size="10">
              <ion-title color="black" class="ion-text-start ion-no-padding pl-2">
                {{ $t('select_customer') }}
              </ion-title>
            </ion-col>
            <ion-col size="2" class="ion-text-right">
              <ion-icon :icon="logOutOutline" @click="openPopover" />
            </ion-col>
          </ion-row>
          <div class="pl-1 pr-1 mt-1">
            <ion-grid>
              <ion-row class="ion-align-items-center">
                <ion-col
                  size="12"
                  class="search-company"
                  :class="isStartSearch ? 'new-search' : 'new-search-off'"
                >
                  <ion-searchbar
                    mode="ios"
                    ref="searchInput"
                    @ionChange="handleSearch"
                    @ionFocus="setSearch(true)"
                    @ionBlur="setSearch(false)"
                    show-clear-button="never"
                    :debounce="1200"
                    :disabled="isOpenLoadingref"
                    class="search"
                    :placeholder="$t('search_customer_admin')"
                    @keyup.enter="handleEnter"
                  >
                  </ion-searchbar>
                  <ion-icon
                    v-show="keySearch.length > 0"
                    class="close-modal"
                    :icon="closeCircle"
                    @click="clearSearch"
                  ></ion-icon>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-events="true" @ionScroll="handleScroll($event)" ref="customerList">
      <skeleton-select-customer v-if="isOpenLoadingref || isSearch" />
      <div v-else>
        <!-- b2b items -->
        <div v-if="totalCountB2bCustomers >= 1">
          <div @click="toggleCollapse('b2b')">
            <div class="header" color="grey7">
              <div class="header-content">
                <div class="text">
                  {{ $t('all_companies') }}
                  <span class="counter" color="grey7">
                    {{ list?.totalCountB2bCustomers ?? 0 }}
                  </span>
                </div>
                <div class="fs-4" :class="['toggle-icon', listCollapse.b2b ? 'rotate-180' : '']">
                  <ion-icon slot="icon-only" :icon="chevronDownOutline" color="black7" />
                </div>
              </div>
            </div>
          </div>
          <Transition name="slide-up">
            <div v-if="listCollapse.b2b">
              <div slot="content" v-for="item in list?.b2bCustomers || []" :key="item.id">
                <card-company
                  :activeTab="activeTab"
                  :currentPage="currentPage"
                  :item="item"
                  :customerSelect="selectedCompany"
                  @onSelectCustomer="applyCustomer(item)"
                />
              </div>
            </div>
          </Transition>
        </div>

        <!-- b2c items -->
        <div v-if="totalCountB2cCustomers >= 1" style="margin-top: 10px">
          <div @click="toggleCollapse('b2c')">
            <div class="header" color="grey7">
              <div class="header-content">
                <div class="text">
                  {{ $t('all_consumers') }}
                  <span class="counter" color="grey7">
                    {{ list?.totalCountB2cCustomers ?? 0 }}
                  </span>
                </div>
                <div class="fs-4" :class="['toggle-icon', listCollapse.b2c ? 'rotate-180' : '']">
                  <ion-icon slot="icon-only" :icon="chevronDownOutline" color="black7" />
                </div>
              </div>
            </div>
          </div>
          <Transition name="slide-up">
            <div v-if="listCollapse.b2c">
              <div slot="content" v-for="item in list?.b2cCustomers || []" :key="item.id">
                <card-company
                  :activeTab="activeTab"
                  :currentPage="currentPage"
                  :item="item"
                  :customerSelect="selectedCompany"
                  @onSelectCustomer="applyCustomer(item)"
                />
              </div>
            </div>
          </Transition>
        </div>
        <boarding-state
          v-else-if="isShowBoarding"
          @refreshAct="getData"
          :currentStep="totalCountB2bCustomers <= 0 && totalCountB2cCustomers <= 0 ? 1 : 2"
        />
        <div
          v-else-if="totalCountB2bCustomers <= 0 && showZeroResult && !loading"
          class="company-not-found mx-4"
        >
          <ion-img src="/assets/images/not-found.svg" class="not-found-img" />
          <div class="not-found-text mt-3 mb-2">{{ $t('search_company_not_found') }}</div>
          <div class="try-different">
            {{
              `${$t('sorry_could_not_find')} ${keySearch === '' ? refKeySearch : keySearch}. ${$t(
                'try_different_name_admin'
              )}`
            }}
          </div>
        </div>
        <ion-infinite-scroll
          @ionInfinite="loadMore($event)"
          threshold="100px"
          id="infinite-scroll"
          :disabled="isDisabled"
        >
          <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading ...">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </div>
    </ion-content>
    <ion-footer
      class="form-group px-3 py-3"
      v-if="
        (currentPage === '/sale/customers' && !isOpenLoadingref) ||
        (totalCountB2bCustomers <= 0 && totalCountB2cCustomers <= 0 && showZeroResult && !isOpenLoadingref)
      "
    >
      <div class="form-group-btn">
        <ion-button mode="md" color="primary" @click="addCompany"
          ><ion-icon :icon="addOutline" />{{ $t('customerPage.add_company') }}</ion-button
        >
      </div>
    </ion-footer>
    <ion-modal
      :is-open="isOpenRef"
      ref="modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      css-class="default-bottom-sheet-modal"
      @didDismiss="setOpenBranchSelection(false)"
    >
      <branch-selection
        @closeModal="closeModalBranchSelection"
        @resetPath="resetPath"
        :customerSelect="selectedCompany"
        @resetSearch="resetSearch"
      ></branch-selection>
    </ion-modal>
    <ion-modal
      :is-open="isOpenModalLogoutRef"
      css-class="modal-confirm custom-modal"
      @didDismiss="setOpenModalLogout(false)"
    >
      <modal-content-confirm
        :title="$t('sign_out')"
        :content="$t('are_you_sure')"
        @confirm-yes-action="logout"
        @confirm-no-action="setOpenModalLogout(false)"
      >
      </modal-content-confirm>
    </ion-modal>

    <ion-modal ref="modal" :is-open="picPinModalOpen">
      <modal-add-pic-pin @close-modal="onAddPicPin(false)" />
    </ion-modal>
  </ion-page>
</template>

<script>
import ModalAddPicPin from '@/modules/b2b/views/customer/components/ModalAddPicPin.vue';
import ModalContentConfirm from '@/modules/b2b/views/shared/ModalContentConfirm.vue';
import { ACTIONS as ACTIONS_ADDRESS } from '@/modules/sale/store/address/actions';
import { ACTIONS } from '@/modules/sale/store/customer/actions';
import SkeletonSelectCustomer from '@/modules/shared/components/select-customer/SkeletonSelectCustomer.vue';
import { USER_TYPE_ID } from '@/modules/shared/constants';
import { useLoading } from '@/modules/shared/utils/';
import branch from '@/services/shared/helper/branch';
import PushNotification from '@/services/shared/helper/push-notification';
import { ACTIONS as ROOT_ACTIONS } from '@/store/actions';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import {
  addOutline,
  chevronBackOutline,
  chevronDownOutline,
  closeCircle,
  idCardOutline,
  logOutOutline
} from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { createNamespacedHelpers, mapActions as mapActionsRoot } from 'vuex';
import BranchSelection from './BranchSelection.vue';
import BoardingState from './components/BoardingState.vue';
import CardCompany from './components/CardCompany.vue';

const { mapActions, mapGetters } = createNamespacedHelpers('sale/customer');
const { mapActions: mapActionsAddress } = createNamespacedHelpers('sale/address');

export default defineComponent({
  name: 'select-customer',
  components: {
    BranchSelection,
    ModalContentConfirm,
    CardCompany,
    BoardingState,
    SkeletonSelectCustomer,
    ModalAddPicPin
  },
  setup() {
    const { locale } = useI18n();
    const isOpenRef = ref(false);
    const selectedCompany = ref(null);
    const setOpenBranchSelection = (state) => (isOpenRef.value = state);
    const user = ref(null);
    const NoLoadItems = ref(false);
    const route = useRoute();
    const router = useRouter();
    const isOpenModalLogoutRef = ref(false);
    const setOpenModalLogout = (state) => (isOpenModalLogoutRef.value = state);
    const { showLoading } = useLoading();
    const addCompany = () => {
      router.push('/sale/customers/add/');
    };
    return {
      isOpenRef,
      setOpenBranchSelection,
      selectedCompany,
      logOutOutline,
      chevronBackOutline,
      closeCircle,
      idCardOutline,
      addOutline,
      user,
      NoLoadItems,
      showLoading,
      addCompany,
      isOpenModalLogoutRef,
      setOpenModalLogout,
      route,
      locale,
      Keyboard,
      chevronDownOutline,
      keySearch: ref(''),
      refKeySearch: ref(''),
      isDisabled: ref(false),
      customer: ref([]),
      previousSelectedCompanyId: ref(null),
      selectedCustomer: ref(null),
      listCollapse: ref({
        b2b: true,
        b2c: true
      }),
      showZeroResult: ref(false),
      isStartSearch: ref(false),
      isOpenLoadingref: ref(true),
      isSearch: ref(false),
      currentPage: ref(null),
      prevPage: ref(null),
      checkScrollPosition: ref(null),
      activeTab: ref('myCustomer'), // `myCustomer` & `untaggedCustomer`
      picPinModalOpen: ref(false)
    };
  },
  computed: {
    ...mapGetters(['list', 'totalCountB2bCustomers', 'totalCountB2cCustomers', 'customerBranch', 'loading']),
    isShowBoarding() {
      return (
        [USER_TYPE_ID.SALE, USER_TYPE_ID.SUPPLIER].includes(this.user?.type?.id) &&
        this.totalCount === 0 &&
        !this.refKeySearch
      );
    }
  },
  watch: {
    totalCountB2bCustomers() {
      this.showNoCompanyFound();
      if (this.totalCountB2bCustomers > 0 || this.totalCountB2cCustomers > 0) {
        this.isOpenLoadingref = false;
      }
      this.isDisabled = false;
    },
    totalCountB2cCustomers() {
      this.showNoCompanyFound();
      if (this.totalCountB2bCustomers > 0 || this.totalCountB2cCustomers > 0) {
        this.isOpenLoadingref = false;
      }
      this.isDisabled = false;
    },
    keySearch() {
      this.keySearch !== '' ? (this.refKeySearch = this.keySearch) : '';
    },
    list() {
      if (
        this.list.b2bCustomers.length === this.totalCountB2bCustomers &&
        this.list.b2cCustomers.length === this.totalCountB2cCustomers
      ) {
        this.isDisabled = true;
      }
    }
  },
  inject: ['$storage'],
  ionViewDidLeave() {
    this.keySearch = '';
    this.$refs.searchInput.$el.value = '';
    this.isOpenLoadingref = true;
  },
  async ionViewDidEnter() {
    this.isOpenLoadingref = true;
    this.$refs.searchInput.$el.value = '';
    const routeFirstCompany = window.history?.state?.back === '/sale/customers/add/no-companies';
    this.user = await this.$storage.getUser();
    if (routeFirstCompany) this.prevPage = '/sale/main/home';
    else this.prevPage = window.history?.state?.back;
    this.currentPage = this.$route.path;
    if (this.route?.redirectedFrom?.path?.includes('sale/customers')) {
      await this[ACTIONS.RELOAD_CUSTOMERS]();
    }
    await this.getData();
  },
  async mounted() {
    this.currentPage = this.$route.path;
    this.locale = await this.$storage.get('language');
    this.customer = await this.$storage.getSelectCustomer();
    let company = await this.$storage.getSelectedCompany();
    this.user = await this.$storage.getUser();
    this.selectedCustomer = company;
    this.previousSelectedCompanyId = company ? company.id : null;
    this.prevPage = window.history?.state?.back;
  },
  async beforeRouteEnter(_, from) {
    if (from.path === '/sale/add-company') {
      this.keySearch = '';
      await this[ACTIONS.RELOAD_CUSTOMERS](this.keySearch);
    }
  },
  methods: {
    ...mapActions([
      ACTIONS.GET_CUSTOMERS,
      ACTIONS.LOAD_MORE_CUSTOMERS,
      ACTIONS.RELOAD_CUSTOMERS,
      ACTIONS.GET_CUSTOMERS_BRANCH,
      ACTIONS.UPDATE_QUERY_PARAMS,
      ACTIONS.UPDATE_SELECTED_CUSTOMER
    ]),
    ...mapActionsRoot([ROOT_ACTIONS.RESET_STATE]),
    ...mapActionsAddress([ACTIONS_ADDRESS.GET_ADDRESSES]),
    handleEnter() {
      if (Capacitor.isNativePlatform()) {
        this.Keyboard.hide();
      }
    },

    handleScroll(event) {
      this.checkScrollPosition = event?.detail?.scrollTop;
      if (event && Capacitor.isNativePlatform()) {
        this.Keyboard.hide();
      }
    },

    toggleCollapse(name) {
      this.listCollapse[name] = !this.listCollapse[name];
    },
    async handleSearch() {
      const currentSearchKey = this.$refs.searchInput.$el?.value;
      this.keySearch = currentSearchKey;
      this.isSearch = this.keySearch !== '' ? true : false;
      await this[ACTIONS.RELOAD_CUSTOMERS](this.keySearch);
      this.isDisabled = false;
      //time need only for show skeleton loading
      setTimeout(() => {
        this.isSearch = false;
        const content = this.$refs.customerList.$el;
        content.scrollToTop();
      }, 500);
    },
    async clearSearch() {
      this.resetSearch();
      await this[ACTIONS.RELOAD_CUSTOMERS](this.keySearch);
    },
    setSearch(params) {
      this.isStartSearch = params;
    },
    showNoCompanyFound() {
      this.showZeroResult =
        this.totalCountB2bCustomers <= 0 &&
        this.totalCountB2cCustomers <= 0 &&
        this.list?.__typename === 'SaleGetUserCustomerList'
          ? true
          : false;
    },
    async getData() {
      this.isOpenLoadingref = true;
      await this[ACTIONS.UPDATE_QUERY_PARAMS]({
        ...this.params,
        searchQueries: this.keySearch,
        offset: 0,
        isLoadMore: false,
        tenantId: this.user?.tenant?.id
      });
      await this[ACTIONS.GET_CUSTOMERS]({
        params: {
          tenantId: this.user?.tenant?.id
        }
      });
      this.isOpenLoadingref = false;
    },
    loadMore(e) {
      try {
        if (this.NoLoadItems) return;

        this.NoLoadItems = true;
        // check continue status
        if (
          this.list.b2bCustomers.length === this.totalCountB2bCustomers &&
          this.list.b2cCustomers.length === this.totalCountB2cCustomers
        ) {
          this.isDisabled = true;
          return;
        }
        const offset =
          this.list.b2bCustomers.length > this.list.b2cCustomers.length
            ? this.list.b2bCustomers.length
            : this.list.b2cCustomers.length;
        this[ACTIONS.LOAD_MORE_CUSTOMERS]({
          params: {
            isIncludeTotalCount: true,
            offset,
            tenantId: this.user?.tenant?.id
          },
          onFinish: () => {
            e.target.complete();
          }
        });
      } catch (error) {
        this.NoLoadItems = true;
      } finally {
        this.NoLoadItems = false;
      }
    },
    async applyCustomer(customer) {
      await this[ACTIONS.GET_CUSTOMERS_BRANCH]({
        id: customer.id,
        tagged: true
      });
      const details = this.customerBranch?.data?.length > 0 ? this.customerBranch.data[0] : {};
      if (details.branches && details.branches.length > 0) {
        this.selectedCompany = this.customerBranch.data[0];
        await this.$storage.setSelectedCompany(customer);
        await this.$storage.setSelectedCustomer(customer);
        this.setOpenBranchSelection(true);
      } else {
        customer.branches = [];
        this[ACTIONS.UPDATE_SELECTED_CUSTOMER](customer);
        await this.$storage.setSelectedCompany(customer);
        await this.$storage.setSelectedCustomer(customer);
        this.keySearch = '';
        await this[ACTIONS_ADDRESS.GET_ADDRESSES]({
          supplierId: customer.id,
          active: true
        });
        this.$router.replace(this.prevPage ? this.prevPage : '/sale/main/home');
        this.resetPath();
      }
    },
    async checkCompany() {
      let company = await this.$storage.getSelectedCompany();
      this.previousSelectedCompanyId = company ? company.id : null;
      this.selectedCustomer = company;
    },
    async closeModalBranchSelection(hasSelected) {
      if (!hasSelected) {
        this.setOpenBranchSelection(false);
        return;
      }
      let company = await this.$storage.getSelectedCompany();
      await this[ACTIONS_ADDRESS.GET_ADDRESSES]({
        supplierId: company.id,
        active: true
      });
      this.setOpenBranchSelection(false);
      if (hasSelected) this.$router.push(this.prevPage);
    },
    resetPath() {
      // give time to page closed first
      setTimeout(() => {
        this.currentPage = null;
      }, 1000);
    },
    resetSearch() {
      this.$refs.searchInput.$el.value = '';
      this.keySearch = '';
    },
    async openPopover() {
      this.setOpenModalLogout(true);
    },
    async backHome() {
      await this.$storage.setBackChangeCompany(true);
      this.$router.push(this.$route?.params?.path ? this.$route?.params?.path : this.prevPage);
      this.setOpenBranchSelection(true);
    },
    goBack() {
      if (this.currentPage === '/sale/select-customer') {
        this.backHome();
        this.resetPath();
      } else {
        this.$router.push('/sale/account');
      }
    },
    logout() {
      this.showLoading(async () => {
        const user = await this.$storage.getUser();
        return Promise.allSettled([
          // reset state vuex
          this[ROOT_ACTIONS.RESET_STATE](),
          // clear ionic storage
          this.$storage.clearStorage(),
          PushNotification.removeAllListenerAndSubscribe(user?.id),
          branch.logout()
        ])
          .then(() => {
            this.setOpenModalLogout(false);
          })
          .then(() => {
            this.$router.go('');
          });
      });
    },
    onAddPicPin(value) {
      if (value) this.picPinModalOpen = value;
      else this.picPinModalOpen = !this.picPinModalOpen;
    }
  }
});
</script>

<style scoped lang="scss">
.title {
  font-size: 20px;
  line-height: 32px;
}
.search {
  padding-right: 0px;
  padding-left: 0px;
}

#row {
  height: 50px;
}
ion-item {
  --inner-padding-end: 10px;
  --inner-padding-start: 10px;
}
ion-icon {
  font-size: 28px;
  color: red;
}
.counter {
  margin-left: 5px;
  border-radius: 100px;
  padding: 3px 5px;
  font-size: 14px;
  background-color: #f04438;
  color: #fff;
}
.toggle-icon {
  transition: transform 0.3s ease;
  // position: absolute;
  // right: 10px;
  // top: 10px;
}
.rotate-180 {
  transform: rotate(180deg);
}
.header {
  padding: 20px 20px;
  background: #eeeeee;
  font-weight: bold;
}
.company-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  margin-top: 22vh;
}
.not-found-img {
  height: 120px;
  width: 120px;
}
.not-found-text {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.7px;
}
.try-different {
  text-align: center;
}
.add-new-btn {
  margin-top: 10px;
  height: 44px;
  font-size: 16px;
  font-weight: 500;
  --border-radius: 10px;
  & > ion-icon {
    font-weight: 500;
    margin-right: 2px;
  }
}
ion-button {
  text-transform: none;
}
ion-searchbar {
  --background: transparent;
  --icon-color: #00676a;
  border: 3px solid transparent;
  margin-top: -3px;
  font-size: 15px;
}
.new-search {
  border: 1.3px solid #00676a;
  border-radius: 12px;
  height: 45px;
  background: #ffffff;
  color: black;
}
.new-search-off {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  height: 45px;
  background: #f5f5f5;
  color: #9e9e9e;
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.hidden {
  opacity: 0;
}

.align-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.align-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
}
.back-icon {
  margin-top: -6px;
}
.form-group {
  display: flex;
  flex-direction: column;
  &-btn {
    width: 100%;
    & > ion-button {
      margin-top: 10px;
      width: 100%;
      height: 44px;
      font-size: 16px;
      font-weight: 500;
      --border-radius: 10px;
      & > ion-icon {
        font-weight: 500;
        margin-right: 2px;
        color: white;
      }
    }
  }
}
.search-company {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  justify-content: space-between;
}
.close-modal {
  margin-top: 3px;
  margin-right: 4px;
  font-size: 28px;
  color: #9e9e9e;
}
.mt-label {
  margin-top: 2px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.text {
  display: flex;
  align-items: center;
}
ion-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
}
</style>
